export const SalesmanPermissions = {
    0: "審核中",
    1: "使用中",
    "-1": "未通過",
    "-2": "停權",
};
export const SalesmanVerifyLogStatus = {
    0: "審核中",
    1: "審核通過",
    "-1": "未通過",
    "-2": "停權",
};

export const SalesmanStatus = {
    // 審核中
    VERIFY: 0,
    // 審核成功
    ALREADY_APPLIED: 1,
    // 審核失敗
    AUDIT_FAILURE: -1,
    // 已停權
    SUSPENDED: -2,
};
