<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="py-5 mt-4 bg-white rounded-lg">
            <div class="px-[16%]">
                <h2 class="text-lg font-bold">申請人</h2>
                <div class="flex items-center mt-2 w-[300px]">
                    <div class="w-[100px]">
                        <a
                            :href="`/#/user_list/${
                                isProvider ? 'provider_update' : 'member_update'
                            }/${data.user.id}`"
                            target="verifyUser"
                            class="mx-5 text-red-500 underline"
                        >
                            <Avatar
                                :size="['w-10', 'h-10']"
                                :backgroundImg="
                                    data.user.thumbnails.avatar['360x360']
                                "
                            />
                        </a>
                    </div>
                    <a
                        :href="`/#/user_list/${
                            isProvider ? 'provider_update' : 'member_update'
                        }/${data.user.id}`"
                        target="verifyUser"
                        class="mx-5 text-red-500 underline"
                    >
                        {{ data.user.name }}
                    </a>
                    <div
                        class="flex-grow text-right text-red-500"
                        @click="
                            toMessage(
                                data.user.role === 0 ? 'members' : 'providers',
                                data.user.banana_id
                            )
                        "
                    >
                        <i class="fas fa-comment-alt"></i>
                    </div>
                </div>
                <div class="flex w-[300px] mt-2">
                    <div class="text-gray-400 w-[100px]">手機</div>
                    <div class="mx-5">{{ data.user.phone }}</div>
                    <div class="flex-grow text-right">
                        <span
                            class="cursor-pointer"
                            @click="onClipboard(data.user.phone)"
                            ><i class="far fa-clone"></i
                        ></span>
                    </div>
                </div>
                <div class="flex w-[300px] mt-2">
                    <div class="text-gray-400 w-[100px]">身份</div>
                    <div class="mx-5">
                        {{ data.user.role === 0 ? "會員" : "服務商" }}
                    </div>
                </div>
                <div class="mt-10">
                    <ul v-if="!$isEmpty(data.social)">
                        <li
                            v-for="(social, key) in data.social"
                            :key="key"
                            class="mb-2"
                        >
                            <div
                                v-show="key === 'facebook'"
                                class="text-sm text-gray-400"
                            >
                                Facebook
                            </div>
                            <div
                                v-show="key === 'instagram'"
                                class="text-sm text-gray-400"
                            >
                                Instagram
                            </div>
                            <div
                                v-show="key === 'social'"
                                class="text-sm text-gray-400"
                            >
                                其他資源
                            </div>
                            <div>{{ social }}</div>
                        </li>
                    </ul>
                    <div class="mb-2">
                        <div class="text-sm text-gray-400 whitespace-nowrap">
                            聯絡方式
                        </div>
                        <div class="break-all">{{ data.contact }}</div>
                    </div>
                    <div>
                        <div class="text-sm text-gray-400 whitespace-nowrap">
                            留下訊息
                        </div>
                        <div>{{ data.comment }}</div>
                    </div>
                </div>
            </div>
            <div class="my-10 border-b-2 border-gray-100 mx-[8%]"></div>
            <div v-if="route.params.status == 0" class="px-[16%]">
                <h5 class="text-lg font-bold">審核結果</h5>
                <el-form ref="formRefDom" :rules="rules" :model="form">
                    <el-form-item prop="status" class="mt-5">
                        <div>
                            <el-radio
                                v-model="form.status"
                                :disabled="[1, -2].includes(data.status)"
                                :label="1"
                                >審核通過</el-radio
                            >
                        </div>
                        <div>
                            <el-radio
                                v-model="form.status"
                                :disabled="[1, -2].includes(data.status)"
                                :label="-1"
                                >審核未通過</el-radio
                            >
                        </div>
                    </el-form-item>
                    <el-form-item
                        v-if="form.status === -1"
                        prop="comment"
                        class="ml-5"
                    >
                        <el-input
                            v-model="form.comment"
                            type="textarea"
                            :rows="3"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="flex justify-center">
                    <button
                        v-if="isEmpty(data.log)"
                        v-loading="loading"
                        :disabled="loading"
                        class="px-3 py-2 w-[150px] rounded-lg border border-black mr-2 disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:border-gray-300"
                        @click="backToList"
                    >
                        返回列表
                    </button>
                    <button
                        v-if="![1, -2].includes(data.status)"
                        v-loading="loading"
                        v-permission="['update']"
                        :disabled="loading"
                        class="px-3 py-2 w-[150px] text-white border border-red-500 bg-red-500 rounded-lg disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:border-gray-300"
                        @click="onSubmit()"
                    >
                        送出結果
                    </button>
                </div>
            </div>
            <div
                v-if="!isEmpty(data.log) && data.log.status_change.length > 0"
                class="px-[16%] mt-5"
            >
                <h5 class="text-lg font-bold">審核記錄</h5>
                <table class="myTable">
                    <thead>
                        <tr>
                            <th
                                v-for="(item, index) in [
                                    '審核時間',
                                    '審核結果',
                                    '審核人員',
                                    '審核資料',
                                ]"
                                :key="index"
                                class="text-left"
                            >
                                {{ item }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in data.log.status_change
                                .slice()
                                .reverse()"
                            :key="index"
                        >
                            <td>
                                {{ item.time | formatDateTime }}
                            </td>
                            <td class="min-w-[200px]">
                                <div>
                                    {{ SalesmanVerifyLogStatus[item.after] }}
                                </div>
                                <div>{{ item.comment }}</div>
                            </td>
                            <td>
                                {{
                                    Object.keys(item.administrator).length > 0
                                        ? item.administrator.name
                                        : item.administrator
                                }}
                            </td>
                            <td
                                class="underline cursor-pointer"
                                style="color: blue"
                            >
                                <div @click="openDialog(item)">
                                    查看審核資料
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex justify-center mt-5">
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="px-3 py-2 w-[150px] rounded-lg border border-black mr-2 disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:border-gray-300"
                        @click="backToList"
                    >
                        返回列表
                    </button>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            width="500px"
            title="審核歷史資料"
            @close="closeDialog"
        >
            <ul
                v-if="
                    !$isEmpty(dialogData.request) &&
                    !$isEmpty(dialogData.request.social)
                "
            >
                <li
                    v-for="(social, key) in dialogData.request.social"
                    :key="key"
                    class="mb-2"
                >
                    <div
                        v-show="key === 'facebook'"
                        class="text-sm text-gray-400"
                    >
                        Facebook
                    </div>
                    <div
                        v-show="key === 'instagram'"
                        class="text-sm text-gray-400"
                    >
                        Instagram
                    </div>
                    <div
                        v-show="key === 'social'"
                        class="text-sm text-gray-400"
                    >
                        其他資源
                    </div>
                    <div>{{ social }}</div>
                </li>
            </ul>
            <div v-if="!isEmpty(dialogData.request)" class="mb-2">
                <div class="text-sm text-gray-400 whitespace-nowrap">
                    聯絡方式
                </div>
                <div class="break-all">{{ dialogData.request.contact }}</div>
            </div>
            <div v-if="!isEmpty(dialogData.request)">
                <div class="text-sm text-gray-400 whitespace-nowrap">
                    留下訊息
                </div>
                <div>{{ dialogData.request.comment }}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import LoadingComponent from "@/components/Loading.vue";
// 頭像組件
import Avatar from "@/components/Avatar.vue";
// 判斷是否有選擇值
import { checkHaveSelectData, isEmpty } from "@/service/anyService";
// 顯示狀態名稱
import { SalesmanVerifyLogStatus } from "@/config/salesmanConfig";
const __sfc_main = {
  name: "salesman_verify_id"
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  // 判斷是否為服務商
  const isProvider = computed(() => {
    return data.value.user.role === 0 ? false : true;
  });
  const loading = ref(false);
  // 顯示彈窗
  const showDialog = ref(false);
  // 彈窗內資料
  const dialogData = ref({
    request: {}
  });
  // 開啟彈窗
  function openDialog(data) {
    dialogData.value = data;
    showDialog.value = true;
  }
  // 關閉彈窗
  function closeDialog() {
    dialogData.value = {
      request: {}
    };
    showDialog.value = false;
  }
  // 表單 dom
  const formRefDom = ref(null);
  // 表單資料
  const form = ref({
    status: null,
    comment: ""
  });
  // 表單驗證規則
  const allRules = ref({
    status: [{
      required: true,
      message: "請選擇審核狀態",
      trigger: "blur"
    }],
    comment: [{
      required: true,
      message: "請輸入未通過原因",
      trigger: "blur"
    }, {
      max: 250,
      message: "未通過原因最多 255字",
      trigger: "blur"
    }]
  });
  const rules = computed(() => {
    // 判斷選擇 未審核通過時 需判斷是否有輸入審核失敗原因
    if (form.value.status === -1) {
      return allRules.value;
    } else {
      return {
        status: allRules.value.status
      };
    }
  });
  // 資料
  const data = ref({
    user: {
      thumbnails: {
        avatar: {}
      }
    }
  });
  /**
   * @param { type String(字串) } tab 判斷預設選中聊天 tab 是服務商列表還是會員列表
   * @param { type String(字串) } id 會員 banana id
   */
  function toMessage(tab, id) {
    window.open(`/#/firestore/chats/${tab}/${id}`);
  }

  /**
   * 複製活動網址
   * @param { type String(字串) } val 活動網址
   */
  function onClipboard(val) {
    proxy.$copyText(val).then(() => {
      proxy.$message({
        type: "success",
        message: "連結複製成功"
      });
    }, () => {
      prox.$message({
        type: "error",
        message: "連結複製失敗"
      });
    });
  }

  // 驗證表單
  async function onSubmit() {
    try {
      // 表單驗證
      await formRefDom.value.validate();
      // 審核
      await verify();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }
  /**
   * 取得資料
   */
  async function getData() {
    loading.value = true;
    try {
      const {
        data: salesman
      } = await proxy.$api.GetApplyCityPlanSalesmanApi(route.params.id);
      data.value = salesman;
      form.value.status = salesman.status;
      // 判斷有審核紀錄才塞入資料
      if (!isEmpty(salesman.log) && salesman.log.status_change.length > 0 && route.params.status != 0) {
        form.value.comment = salesman.log.status_change[salesman.log.status_change.length - 1].comment;
      }
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 審核城市推廣人狀態
   */
  async function verify() {
    checkHaveSelectData([form.value.status, form.value.comment], ["status", "comment"], form.value);
    loading.value = true;
    try {
      await proxy.$api.VerifyCityPlanSalesmanApi(route.params.id, form.value);
      proxy.$message({
        type: "success",
        message: "審核成功"
      });
      router.push({
        name: "salesman_verify",
        params: {
          status: form.value.status
        }
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "審核失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 返回列表
   */
  function backToList() {
    window.scrollTo(0, 0);
    router.push({
      name: "salesman_verify",
      params: {
        status: route.params.status
      }
    });
  }
  getData();
  onActivated(() => {
    getData();
  });
  return {
    isEmpty,
    SalesmanVerifyLogStatus,
    route,
    isProvider,
    loading,
    showDialog,
    dialogData,
    openDialog,
    closeDialog,
    formRefDom,
    form,
    rules,
    data,
    toMessage,
    onClipboard,
    onSubmit,
    backToList
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent,
  Avatar
}, __sfc_main.components);
export default __sfc_main;
</script>


